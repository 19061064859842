import { useEffect, useState } from 'react';

export function FaceBox ({ videoRef, frameResult }) {
  const [faceBoxes, setFaceBoxes] = useState([]);

  // Parse results and set face boxes
  useEffect(() => {
    if (frameResult) {
      const newFaceBoxes = frameResult.results.map((result, idx) => {
        const { confidence, bounding_box: bbox, landmarks } = result;
        
        const faceBoxStyle = {
          position: 'absolute',
          top: `${bbox.top}%`,
          left: `${bbox.left}%`,
          width: `${bbox.width}%`,
          height: `${bbox.height}%`,
          border: `2px solid red`,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        };
      
        const confidenceStyle = {
          position: 'absolute',
          top: `-20px`,
          backgroundColor: 'white',
          color: 'black',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '0.8em'
        }

        return (
          <div key={idx} style={faceBoxStyle}>
            <div style={confidenceStyle}>{`Confidence: ${confidence}%`}</div>
            {/* Render landmarks here */}
            {landmarks.map((landmark, idx) => {
              const landmarkStyle = {
                position: 'absolute',
                width: '5px',
                height: '5px',
                backgroundColor: 'lime',
                borderRadius: '50%',
                left: `${(landmark.x - bbox.left - 2.5) / bbox.width * 100}%`,
                top: `${(landmark.y - bbox.top - 2.5) / bbox.height * 100}%`,
              };
              return <div key={idx} style={landmarkStyle} title={landmark.name}></div>;
            })}
          </div>
        );
      });

      setFaceBoxes(newFaceBoxes);
    }
  }, [frameResult]);

  const videoContainerStyle = {
    position: 'relative',
    width: 'fit-content',
  };

  const videoStyle = {
    width: '100%',
  };

  return (
    <div style={videoContainerStyle}>
      <video ref={videoRef} style={videoStyle} controls/>
      {faceBoxes}
    </div>
  );
};
