import { useEffect, useState } from 'react';

export function ObjectBox({ videoRef, frameResult }) {
  const [objectBoxes, setObjectBoxes] = useState([]);

  useEffect(() => {
    if (frameResult) {
      const newObjectBoxes = frameResult.results.map((result, idx) => {
        const { categoryName, score, boundingBox: bbox } = result;
        
        const objectBoxStyle = {
          position: 'absolute',
          top: `${bbox.top}%`,
          left: `${bbox.left}%`,
          width: `${bbox.width}%`,
          height: `${bbox.height}%`,
          border: `2px solid blue`,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        };

        const labelStyle = {
          position: 'absolute',
          top: `-20px`,
          backgroundColor: 'white',
          color: 'black',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '0.8em'
        }

        return (
          <div key={idx} style={objectBoxStyle}>
            <div style={labelStyle}>
              {`${categoryName} (${Math.round(score * 100)}%)`}
            </div>
          </div>
        );
      });

      setObjectBoxes(newObjectBoxes);
    }
  }, [frameResult]);

  const videoContainerStyle = {
    position: 'relative',
    width: 'fit-content',
  };

  const videoStyle = {
    width: '100%',
  };

  return (
    <div style={videoContainerStyle}>
      <video ref={videoRef} style={videoStyle} controls/>
      {objectBoxes}
    </div>
  );
};
