//import logo from './logo.svg';
import './App.css';
import MediaPipeFaceDetection from './components/MediaPipeFaceDetection';
import MediaPipeObjectDetection from './components/MediaPipeObjectDetection.jsx';

function App() {
  return (
    <div>
      <MediaPipeFaceDetection />
      <MediaPipeObjectDetection />
    </div>
  );
}

export default App;
