import React, { useRef, useState, useEffect } from "react";
//import { groupFaceDetection } from "./utils";
import {
  ObjectDetector,
  FilesetResolver
} from "@mediapipe/tasks-vision";
import { ObjectBox } from "./ObjectBox";

export default function MediaPipeObjectDetection() {
  const fileUploadRef = useRef(null);
  const detectorRef = useRef(null);  
  const videoRef = useRef(null);
  const [debugMode, setDebugMode] = useState(false);
  const debugModeRef = useRef(debugMode);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingDone, setProcessingDone] = useState(false);
  const [frameResults, setFrameResults] = useState([]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    videoRef.current.src = fileURL;
    setIsFileUploaded(true);  // Set file uploaded state to true
  };


  //****************************
  //* Initialize Detector
  //***************************
  useEffect(() => {    
    const initializeDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
      );
      const detector = await ObjectDetector.createFromOptions(vision, {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-tasks/object_detector/efficientdet_lite0_uint8.tflite`
          },
          runningMode: "IMAGE",
          scoreThreshold: 0.5
      });
      console.log('detector', detector)
      detectorRef.current = detector;
      console.log('detector loaded');
    };
    initializeDetector();
  }, []);

  useEffect(() => {
    videoRef.current.addEventListener('seeked', handleSeeked);
  
    return () => {
      videoRef.current.removeEventListener('seeked', handleSeeked);
    };
  }, []);

  const handleSeeked = () => {    
    const time = videoRef.current.currentTime;
    processNextFrame(time);
  };

  const handleSeekAndProcess = async () => { 
    setIsProcessing(true);   
    videoRef.current.currentTime = 0;
  };

  useEffect(() => {
    // Log whenever frameResults changes
    //console.log('frameResults:', frameResults);
  }, [frameResults]);

  useEffect(() => {
    if (isProcessingDone) {
      setIsProcessing(false);  // Indicate processing has completed
    }
  }, [isProcessingDone]);

  // Update debugModeRef.current whenever debugMode changes
  useEffect(() => {
    debugModeRef.current = debugMode;
  }, [debugMode]);

  const processNextFrame = (time) => {
    const image = videoRef.current
    analyzeFrame(image).then((frame) => {  
      //console.log('frame', JSON.stringify(frame))
      setFrameResults(prevState => [...prevState, frame]);
      
      // Only increment time by 0.2 if debug mode is not enabled
      const nextTime = debugModeRef.current ? time : time + 0.2;
      if (nextTime <= videoRef.current.duration) {
        videoRef.current.currentTime = nextTime;
      } else {
        setIsProcessing(false);
        setProcessingDone(true);
      }
    });
  };


  function parseData(data, videoRef) {
    // Parse JSON if it's a string
    if (typeof data === 'string') {
        data = JSON.parse(data);
    }

    if (!videoRef || !videoRef.current) {
        console.error('videoRef or videoRef.current is undefined');
        return [];
    }

    // Map data to new structure
    return data.map(item => {
        const top = parseFloat(((item.boundingBox.originY / videoRef.current.videoHeight) * 100).toFixed(3));
        const left = parseFloat(((item.boundingBox.originX / videoRef.current.videoWidth) * 100).toFixed(3));
        const w = parseFloat(((item.boundingBox.width / videoRef.current.videoWidth) * 100).toFixed(3));
        const h = parseFloat(((item.boundingBox.width / videoRef.current.videoHeight) * 100).toFixed(3));

        return {
            categoryName: item.categories[0].categoryName,
            score: item.categories[0].score,
            boundingBox: {
                top: top,
                left: left,
                width: w,
                height: h
            }
        }
    });
  }

  // Analyze frame using ObjectDetector
  const analyzeFrame = async (image) => {

    if (!detectorRef.current) {
      console.log("Wait for objectDetector to load before clicking");
      return;
    }

    const detections = await detectorRef.current.detect(image).detections;  
    //console.log('detections', JSON.stringify(detections))
    
    let results = parseData(detections, videoRef)
    
    const currentTime = parseFloat((videoRef.current.currentTime).toFixed(1));
    const frame = {
      seconds: currentTime,
      results: results,
      count: results.length
    }
    return frame
  };

  return (
    <>
      <h1 className="text-2xl font-bold mb-4">Object Detection</h1>
      <div className="mb-8">
        <input
          type="file"
          ref={fileUploadRef}
          accept="video/*"
          onChange={handleFileUpload}
          className="py-2 px-4 text-sm text-white bg-blue-500 hover:bg-blue-400 rounded shadow cursor-pointer"
        />
      </div>

      {/* Add a checkbox to toggle debug mode */}
      <div className="mb-4">
        <input
          type="checkbox"
          id="debugMode"
          checked={debugMode}
          onChange={() =>{
            setDebugMode(prevDebugMode => !prevDebugMode)
          }}
        />
        <label htmlFor="debugMode" className="ml-2">Enable Debug Mode</label>
      </div>
      
      <ObjectBox videoRef={videoRef} frameResult={frameResults[frameResults.length - 1]} />

      {/* Kept the canvas element but added a style to hide it */}
      <button 
        onClick={handleSeekAndProcess} 
        disabled={!isFileUploaded || isProcessing}  // Disable button if no file uploaded or processing is ongoing
        className={`py-2 px-4 text-sm text-white bg-blue-500 hover:bg-blue-400 rounded shadow cursor-pointer ${!isFileUploaded || isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {isProcessing ? 'Processing...' : isProcessingDone ? 'Process' : 'Process'}
      </button>    
    </>
  );
}
