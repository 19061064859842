import React, { useRef, useState, useEffect } from "react";
//import { groupFaceDetection } from "./utils";
import {
  FaceDetector,
  FilesetResolver
} from "@mediapipe/tasks-vision";
import { FaceBox } from "./FaceBox";

export default function MediaPipeFaceDetection() {
  const fileUploadRef = useRef(null);
  const detectorRef = useRef(null);  
  const videoRef = useRef(null);
  const [debugMode, setDebugMode] = useState(false);
  const debugModeRef = useRef(debugMode);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingDone, setProcessingDone] = useState(false);
  const [frameResults, setFrameResults] = useState([]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    videoRef.current.src = fileURL;
    setIsFileUploaded(true);  // Set file uploaded state to true
  };


  //****************************
  //* Initialize Detector
  //***************************
  useEffect(() => {    
    const initializeDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
      );
      const detector = await FaceDetector.createFromOptions(vision, {
          baseOptions: {
              modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
              delegate: "GPU"
          },
          runningMode: "IMAGE",
      });
      console.log('detector', detector)
      detectorRef.current = detector;
      console.log('face detector loaded');
    };
    initializeDetector();
  }, []);

  useEffect(() => {
    videoRef.current.addEventListener('seeked', handleSeeked);
  
    return () => {
      videoRef.current.removeEventListener('seeked', handleSeeked);
    };
  }, []);

  const handleSeeked = () => {    
    const time = videoRef.current.currentTime;
    processNextFrame(time);
  };

  const handleSeekAndProcess = async () => { 
    setIsProcessing(true);   
    videoRef.current.currentTime = 0;
  };

  useEffect(() => {
    // Log whenever frameResults changes
    //console.log('frameResults:', frameResults);
  }, [frameResults]);

  useEffect(() => {
    if (isProcessingDone) {
      setIsProcessing(false);  // Indicate processing has completed
    }
  }, [isProcessingDone]);

  // Update debugModeRef.current whenever debugMode changes
  useEffect(() => {
    debugModeRef.current = debugMode;
  }, [debugMode]);

  const processNextFrame = (time) => {
    const image = videoRef.current
    analyzeFrame(image).then((frame) => {      
      setFrameResults(prevState => [...prevState, frame]);
      
      // Only increment time by 0.2 if debug mode is not enabled
      const nextTime = debugModeRef.current ? time : time + 0.2;
      if (nextTime <= videoRef.current.duration) {
        videoRef.current.currentTime = nextTime;
      } else {
        setIsProcessing(false);
        setProcessingDone(true);
      }
    });
  };

  // Analyze frame using FaceDetector
  const analyzeFrame = async (image) => {

    if (!detectorRef.current) {
      console.log("Wait for faceDetector to load before clicking");
      return;
    }

    const detections = await detectorRef.current.detect(image).detections;    
    var results = [];    
    for (let detection of detections) {
      const prediction = Math.round(parseFloat(detection.categories[0].score) * 100)
      // videoRef.current.videoWidth
      const top = parseFloat(((detection.boundingBox.originY / videoRef.current.videoHeight) * 100).toFixed(3));
      const left = parseFloat(((detection.boundingBox.originX / videoRef.current.videoWidth) * 100).toFixed(3));
      const w = parseFloat(((detection.boundingBox.width / videoRef.current.videoWidth) * 100).toFixed(3));
      const h = parseFloat(((detection.boundingBox.width / videoRef.current.videoHeight) * 100).toFixed(3));

      let landmarks = []
      for (let index = 0; index < detection.keypoints.length; index++) {
        const keypoint = detection.keypoints[index];
        
        const x = parseFloat((keypoint.x * 100).toFixed(3));
        const y = parseFloat((keypoint.y * 100).toFixed(3));
      
        const indexToNameMap = {
          0: 'right_eye',
          1: 'left_eye',
          2: 'nose',
          3: 'mouth',
          4: 'right_earlobe',
          5: 'left_earlobe'
        };
        
        let name = indexToNameMap[index];        

        landmarks.push({
          name: name,
          x: x,
          y: y
        })
      }

      const result = {
        confidence: prediction,
        bounding_box: {
          left: left,
          top: top,
          width: w,
          height: h
        },
        landmarks: landmarks
      }
      const faceArea = w * h;
      const frameArea = (videoRef.current.videoWidth * videoRef.current.videoHeight);
      const faceAreaPercent = parseFloat(((faceArea / frameArea) * 100).toFixed(3) * 100);
      result.area_percent = faceAreaPercent;
      results.push(result);
    }
    const currentTime = parseFloat((videoRef.current.currentTime).toFixed(1));
    const frame = {
      seconds: currentTime,
      results: results,
      count: results.length
    }
    return frame
  };

  return (
    <>
      <h1 className="text-2xl font-bold mb-4">Face Detection</h1>

      <div className="mb-8">
        <input
          type="file"
          ref={fileUploadRef}
          accept="video/*"
          onChange={handleFileUpload}
          className="py-2 px-4 text-sm text-white bg-blue-500 hover:bg-blue-400 rounded shadow cursor-pointer"
        />
      </div>

      {/* Add a checkbox to toggle debug mode */}
      <div className="mb-4">
        <input
          type="checkbox"
          id="debugMode"
          checked={debugMode}
          onChange={() =>{
            setDebugMode(prevDebugMode => !prevDebugMode)
          }}
        />
        <label htmlFor="debugMode" className="ml-2">Enable Debug Mode</label>
      </div>
      
      <FaceBox videoRef={videoRef} frameResult={frameResults[frameResults.length - 1]} />

      {/* Kept the canvas element but added a style to hide it */}
      <button 
        onClick={handleSeekAndProcess} 
        disabled={!isFileUploaded || isProcessing}  // Disable button if no file uploaded or processing is ongoing
        className={`py-2 px-4 text-sm text-white bg-blue-500 hover:bg-blue-400 rounded shadow cursor-pointer ${!isFileUploaded || isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {isProcessing ? 'Processing...' : isProcessingDone ? 'Process' : 'Process'}
      </button>      
    </>
  );
}
